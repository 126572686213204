import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profile"
export default class extends Controller {
  connect() {
    console.log("Profile component connected!");
  };

  static targets = [ "name", "title", "button"];

  validateCreation() {
    const name = this.nameTarget.value;
    const title = this.titleTarget.value;
    const isValid = (name.trim() && title.trim());
    this.buttonTarget.disabled = !isValid;
  };
}
