import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auth"
export default class extends Controller {
  connect() {
    console.log("Authentication component connected!");
  };

  static targets = ["email", "password", "confirmation", "username", "name", "title", "button"];

  validateLogin() {
    const email = this.emailTarget.value;
    const pass = this.passwordTarget.value;
    const isValid = (email.trim() && pass.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validateRegistration() {
    const email = this.emailTarget.value;
    const pass = this.passwordTarget.value;
    const confirm = this.confirmationTarget.value;
    const isValid = (email.trim() && pass.trim() && confirm.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validateInvitation() {
    const email = this.emailTarget.value;
    const isValid = (email.trim() && email.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validateInvitationRegistration() {
    const pass = this.passwordTarget.value;
    const confirm = this.confirmationTarget.value;
    const isValid = (pass.trim() && confirm.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validatePasswordReset() {
    const email = this.emailTarget.value;
    const isValid = (email.trim() && email.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validateProfile() {
    const name = this.nameTarget.value;
    const title = this.titleTarget.value;
    const isValid = (name.trim() && title.trim());
    this.buttonTarget.disabled = !isValid;
  };

  validateTeam() {
    const name = this.nameTarget.value;
    const isValid = (name.trim() && name.trim());
    this.buttonTarget.disabled = !isValid;
  };
}
