import { Controller } from "@hotwired/stimulus"
import { marked } from "marked";

// Connects to data-controller="post"
export default class extends Controller {
  static targets = ["message"];

  connect() {
    console.log("PostController connected!");
    this.renderMarkdown();
  };
  
  renderMarkdown() {
    this.messageTarget.innerHTML = marked(this.messageTarget.innerHTML);
  };
}
