import consumer from "./consumer"

consumer.subscriptions.create("LikesChannel", {
  connected() {
    console.log("Connected to LikesChannel");
  },
  disconnected() {
    console.log("Disconnected from LikesChannel");
  }
});
