import consumer from "./consumer"

consumer.subscriptions.create("PostsChannel", {
  connected() {
    console.log("Connected to PostsChannel");
  },
  disconnected() {
    console.log("Disconnected from PostsChannel");
  }
});
