import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    console.log("Clipboard component connected!");
  };

  static targets = ["link"];

  copy() {
    const btn = this.linkTarget;
    const link = btn.textContent;

    navigator.clipboard.writeText(link).then(() => {
      btn.textContent = "Copied!";
      
      setTimeout(() => {
        btn.blur();
        btn.textContent = link;
      }, 5000);
    }).catch(error => {
      console.error("Error copying the link to the clipboard. Details: ", error);
    });
  };
}
