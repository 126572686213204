// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

document.addEventListener("turbo:load", () => {
  // Handle Posts
  const postElement = document.querySelector("[data-post-id]")
  const postId = postElement?.dataset.postId

  if (postId) {
    const channel = consumer.subscriptions.create({ channel: "PostsChannel", post_id: postId }, {
      connected() {
        console.log("Connected to PostsChannel", postId)
      },
      disconnected() {
        console.log("Disconnected from PostsChannel", postId)
      }
    })
  };

  // Handle Likes
  const likeableElement = document.querySelector("[data-likeable-type][data-likeable-id]");

  if (likeableElement) {
    const likeableType = likeableElement.dataset.likeableType;
    const likeableId = likeableElement.dataset.likeableId;

    const channel = consumer.subscriptions.create({ channel: "LikesChannel", likeable_type: likeableType, likeable_id: likeableId }, {
      received(data) {
        const likeableLikesElement = document.querySelector(`[data-likeable-type="${likeableType}"][data-likeable-id="${likeableId}"] .likes`);
        if (likeableLikesElement) {
          likeableLikesElement.outerHTML = data.html;
        }
      }
    });
  };
});

export default createConsumer();
