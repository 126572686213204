import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [
    "collapsed", "expanded", "colKind", "expKind", "selectedKind", "title", "message", "input", "dialog", "dropArea", "thumbnails", "nveCheck", "nveBtn", "slack", "submit"
  ];

  connect() {
    this.expanded = false;
    document.addEventListener("turbo:submit-end", this.resetForm.bind(this));

    this.dropAreaTarget.addEventListener("dragover", this.dragover.bind(this));
    this.dropAreaTarget.addEventListener("dragleave", this.dragleave.bind(this));
    this.dropAreaTarget.addEventListener("drop", this.drop.bind(this));

    this.updateNotifyButton();
  };

  selectKind(event) {
    const clickedBtn = event.currentTarget;
    
    this.expKindTargets.forEach(button => button.classList.remove(button.dataset.kind));
    clickedBtn.classList.add(clickedBtn.dataset.kind);
    this.selectedKindTarget.value = clickedBtn.dataset.kind;
  };

  expand(event) {
    this.expanded = true;
    event.stopPropagation();

    this.expandedTarget.classList.remove("hidden");
    this.collapsedTarget.classList.add("hidden");

    this.titleTarget.value = "";
    this.messageTarget.value = "";
  };

  expandWithKind(event) {
    const clickedBtn = event.currentTarget;

    this.expKindTargets.forEach(button => button.classList.remove(button.dataset.kind));
    !this.expanded && this.expand(event);

    clickedBtn.tagName === 'BUTTON'
      ? this.expKindTargets.filter(button => button.dataset.kind === clickedBtn.dataset.kind && button.classList.add(clickedBtn.dataset.kind))
      : this.expKindTargets.filter(button => button.dataset.kind === 'thread' && button.classList.add('thread'));
    this.selectedKindTarget.value = clickedBtn.tagName === 'BUTTON' ? clickedBtn.dataset.kind : 'thread';
  };

  collapse(event) {
    event.stopPropagation();
    const confirmCollapse = confirm("You have unsaved changes. Are you sure you want to discard them?");

    if(confirmCollapse) {
      this.resetForm();
    };
  };

  triggerFileDialog(event) {
    event.preventDefault();
    this.dialogTarget.click();
  }

  dragover(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.add("dragover");
  }

  dragleave(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.remove("dragover");
  }

  drop(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.remove("dragover");
  
    console.log(event);
    const files = Array.from(event.explicitOriginalTarget.files);
    this.inputTarget.files = files;
  
    files.forEach(file => {
      const reader = new FileReader();
      const thumbnail = document.createElement('div');
      thumbnail.classList.add('thumbnail', 'relative', 'w-24', 'h-24', 'overflow-hidden', 'border', 'border-transparent', 'shadow-md');
  
      const progressWrapper = document.createElement('div');
      progressWrapper.classList.add('progress-wrapper', 'absolute', 'inset-0', 'bg-white', 'opacity-75', 'flex', 'items-center', 'justify-center');
  
      const progressBar = document.createElement('div');
      progressBar.classList.add('progress-bar', 'h-2', 'bg-white', 'w-0');
  
      progressWrapper.appendChild(progressBar);
      thumbnail.appendChild(progressWrapper);
  
      const overlay = document.createElement('div');
      overlay.classList.add('overlay', 'absolute', 'inset-0', 'bg-sky-400', 'opacity-0', 'hover:opacity-75', 'flex', 'items-center', 'justify-center');
  
      const removeButton = document.createElement('button');
      removeButton.classList.add('remove-button', 'text-white', 'font-black');
      removeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5"><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>';
      removeButton.addEventListener('click', () => {
        thumbnail.remove();
      });
  
      overlay.appendChild(removeButton);
      thumbnail.appendChild(overlay);
      this.thumbnailsTarget.appendChild(thumbnail);
  
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.round((e.loaded / e.total) * 100);
          progressBar.style.width = `${progress}%`;
        }
      };
  
      reader.onload = (e) => {
        thumbnail.removeChild(progressWrapper);
        const image = new Image();
        image.src = e.target.result;
        image.classList.add('w-full', 'h-full', 'object-cover');
        thumbnail.appendChild(image);

        const input = this.inputTarget;
        input.files = [file];
        const changeEvent = new Event('change', { bubbles: true });
        input.dispatchEvent(changeEvent);
      };
  
      reader.readAsDataURL(file);
    });
  };  

  handleFiles(event) {
    const files = Array.from(event.target.files);
  
    files.forEach(file => {
      const reader = new FileReader();
      const thumbnail = document.createElement('div');
      thumbnail.classList.add('thumbnail', 'relative', 'w-24', 'h-24', 'overflow-hidden', 'border', 'border-transparent', 'shadow-md');
  
      const progressWrapper = document.createElement('div');
      progressWrapper.classList.add('progress-wrapper', 'absolute', 'inset-0', 'bg-white', 'opacity-75', 'flex', 'items-center', 'justify-center');
  
      const progressBar = document.createElement('div');
      progressBar.classList.add('progress-bar', 'h-2', 'bg-white', 'w-0');
  
      progressWrapper.appendChild(progressBar);
      thumbnail.appendChild(progressWrapper);
  
      const overlay = document.createElement('div');
      overlay.classList.add('overlay', 'absolute', 'inset-0', 'bg-sky-400', 'opacity-0', 'hover:opacity-75', 'flex', 'items-center', 'justify-center');
  
      const removeButton = document.createElement('button');
      removeButton.classList.add('remove-button', 'text-white', 'font-black');
      removeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5"><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" /></svg>';
      removeButton.addEventListener('click', () => {
        thumbnail.remove();
      });
  
      overlay.appendChild(removeButton);
      thumbnail.appendChild(overlay);
      this.thumbnailsTarget.appendChild(thumbnail);
  
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.round((e.loaded / e.total) * 100);
          progressBar.style.width = `${progress}%`;
        }
      };
  
      reader.onload = (e) => {
        thumbnail.removeChild(progressWrapper);
        const image = new Image();
        image.src = e.target.result;
        image.classList.add('w-full', 'h-full', 'object-cover');
        thumbnail.appendChild(image);
      };
  
      reader.readAsDataURL(file);
    });
  };

  toggleNotifyCheckbox() {
    this.nveCheckTarget.checked = !this.nveCheckTarget.checked;
    this.updateNotifyButton();
  };

  updateNotifyButton() {
    if (this.nveCheckTarget.checked) {
      this.nveBtnTarget.classList.add('enabled:bg-sky-700');
      this.nveBtnTarget.classList.remove('enabled:bg-sky-500');
      this.nveBtnTarget.setAttribute('aria-pressed', true);
    } else {
      this.nveBtnTarget.classList.add('enabled:bg-sky-500');
      this.nveBtnTarget.classList.remove('enabled:bg-sky-700');
      this.nveBtnTarget.setAttribute('aria-pressed', false);
    };
  };

  resetForm() {
    this.expanded = false;
    this.expandedTarget.classList.add("hidden");
    this.collapsedTarget.classList.remove("hidden");
    this.expKindTargets.forEach(button => button.classList.remove(button.dataset.kind));
    this.titleTarget.value = "";
    this.messageTarget.value = "";
    this.inputTarget.value = '';
    this.thumbnailsTarget.innerHTML = '';
    this.nveCheckTarget.checked = false;
    this.updateNotifyButton();
  };

  validatePost() {
    const title = this.titleTarget.value;
    const message = this.messageTarget.value;
    const isValid = (title.trim() && message.trim());
    
    this.nveBtnTarget.disabled = this.slackTarget.disabled = this.submitTarget.disabled = !isValid;
  };
}
