import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { page: Number, per: Number, total: Number };

  connect() {
    console.log("ScrollController connected"); // Log connection status
    this.pageValue = this.pageValue || 1;
    window.addEventListener('scroll', this.onScroll.bind(this));
  };

  disconnect() {
    window.removeEventListener('scroll', this.onScroll.bind(this));
  };

  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 2) {
      console.log("Scrolled to bottom");
      console.log(this.totalValue, this.pageValue, this.perValue);
      if (this.totalValue > this.pageValue * this.perValue) {
        this.loadMore();
      };
    };
  };

  loadMore() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set('page', this.pageValue + 1);

    fetch(`${url.pathname}?${params.toString()}`, {
      headers: { Accept: "text/vnd.turbo-stream.html" }
    })
    .then(response => response.ok ? response.text() : Promise.reject(response.statusText))
    .then(html => {
      this.pageValue++;
      if (html.trim() === "") {
        window.removeEventListener('scroll', this.onScroll.bind(this));
      } else {
        document.getElementById("posts").insertAdjacentHTML("beforeend", html);
      }
    })
    .catch(error => console.error("Failed to load more posts:", error));
  };
}
