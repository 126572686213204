import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav"
export default class extends Controller {
  static targets = ["avatar", "menu"];

  connect() {
    console.log("nav controller connected!");
  };

  toggle(event) {
    event.preventDefault();
    const menu = this.menuTarget;

    if (menu.classList.contains("hidden")) {
      // Show the menu
      menu.classList.remove("hidden", "opacity-0", "scale-95");
      menu.classList.add("opacity-100", "scale-100", "transition", "ease-out", "duration-100");
    } else {
      // Hide the menu
      menu.classList.remove("opacity-100", "scale-100");
      menu.classList.add("hidden", "opacity-0", "scale-95", "transition", "ease-in", "duration-75");
    };
  };
}
