import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reply"
export default class extends Controller {
  static targets = ["replyForm", "replyBox"]

  connect() {
    this.replyBoxTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.submitForm();
      }
    });

    document.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.formSubmission.formElement === this.replyFormTarget) {
        this.resetForm();
      };
    });
  };

  submitForm() {
    if (this.replyBoxTarget.value.trim() !== "") {
      this.replyFormTarget.requestSubmit();
    };
  };

  resetForm() {
    this.replyBoxTarget.value = "";
    this.replyBoxTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.replyBoxTarget.dispatchEvent(new Event('change', { bubbles: true }));
  };
}